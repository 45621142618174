import { api } from '../api'

export function getFilterRules (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/filters`)
}

export function disableFilterRule (brand, userName, filterId) {
  return api.patch(
    `api/v1/brands/${brand}/customers/${userName}/filters/${filterId}`,
    { active: false }
  )
}

export function deleteFilterRule (brand, userName, filterId) {
  return api.delete(
    `api/v1/brands/${brand}/customers/${userName}/filters/${filterId}`
  )
}

export function downloadSieveScript (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/sieve-script`)
}
