import * as api from '@/services/groups.js'

const state = {
  loading: true,
  groups: [],
  error: undefined
}

const getters = {
  getGroupById: (state) => (id, customerid) =>
    state.groups.find(
      (group) => group.id === id && group.customer === customerid
    )
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setGroups (state, groups) {
    state.groups = groups
  },
  deleteGroup (state, { id, customer }) {
    const index = state.groups.findIndex(
      (i) => i.id === id && i.customer === customer
    )
    if (index !== -1) state.groups.splice(index, 1)
  },
  addGroup (state, group) {
    state.groups.push(group)
  }
}

const actions = {
  getGroups ({ commit }) {
    commit('changeLoadingState', true)
    return api
      .getGroups()
      .then((groups) => {
        commit('setGroups', groups)
        commit('changeLoadingState', false)
      })
      .catch((error) => console.error(error))
  },
  getGroup ({ commit }, { name, customer }) {
    return api.getGroup(name, customer).then((group) => {
      commit('deleteGroup', name, customer)
      commit('addGroup', { ...group, name })
    })
  },
  deleteGroup ({ commit }, { id, customer }) {
    return api
      .deleteGroup(id, customer)
      .then(() => commit('deleteGroup', { id, customer }))
  },
  createGroup ({ commit }, { group, customer }) {
    return api
      .createGroup(group, customer)
      .then((id) => commit('addGroup', { ...group, id }))
  },
  updateGroup ({ commit }, { group, customer }) {
    return customer ? api.updateGroup(group, customer) : api.updateGroup(group)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
