import * as api from '@/services/brands.js'

const state = {
  loading: true,
  brands: [],
  parents: [],
  availableBrands: [],
  selectedBrand: {}
}

const getters = {
  getBrandById: (state) => (id, customerid) =>
    state.brands.find(
      (brand) => brand.id === id && brand.customer === customerid
    ),
  hasFeature: (state) => (name, feature) => {
    const brand = state.brands.find((brand) => brand.name === name)
    return brand && brand.features
      ? brand.features.some((feat) => feat === feature)
      : false
  }
}

const mutations = {
  changeBrandLoadingState (state, loading) {
    state.loading = loading
  },
  setBrands (state, { brands }) {
    state.brands = brands
  },
  setParents (state, { brandIds }) {
    state.parents = brandIds
  },
  setAvailableBrands (state, { userAssignedBrands }) {
    state.availableBrands = state.brands.filter((brand) =>
      userAssignedBrands.some(
        (userAssignedBrand) => userAssignedBrand.name === brand.name
      )
    )
  },
  addBrand (state, brand) {
    state.brands.push(brand)
  },
  deleteBrand (state, id, customerid) {
    const index = state.brands.findIndex(
      (i) => i.id === id && i.customer === customerid
    )
    state.brands.splice(index, 1)
  },
  selectBrand (state, brand) {
    if (!brand) return
    state.selectedBrand = brand
    localStorage.setItem('brand', brand.id)
  }
}

const actions = {
  getBrands ({ commit }, userAssignedBrands) {
    commit('changeBrandLoadingState', true)
    return api
      .getBrands()
      .then((brands) => {
        commit('setBrands', { brands })
        if (userAssignedBrands) { commit('setAvailableBrands', { userAssignedBrands }) }
        const savedBrand = localStorage.getItem('brand')
        if (savedBrand) {
          commit(
            'selectBrand',
            state.availableBrands.find((brand) => brand.id === savedBrand)
          )
        } else if (
          state.availableBrands.length > 0 &&
          state.selectedBrand &&
          !state.selectedBrand.name
        ) {
          // TODO: Check if this branch is still needed and refactor/remove
          commit('selectBrand', state.availableBrands[0])
        }
        commit('changeBrandLoadingState', false)
      })
      .catch((error) => {
        // TODO: Check if we want to reset loading state in error case
        console.error(error)
      })
  },
  getParents ({ commit }) {
    commit('changeBrandLoadingState', true)
    return api
      .getParents()
      .then((brandIds) => {
        commit('setParents', { brandIds })
        commit('changeBrandLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  createBrand ({ commit }, { brand, customer }) {
    return api
      .createBrand(brand, customer)
      .then((id) => commit('addBrand', { ...brand, id }))
  },
  updateBrand ({ commit }, { brand, customer }) {
    return api.updateBrand(brand, customer)
  },
  deleteBrand ({ commit }, { id, customer }) {
    api
      .deleteBrand(id, customer)
      .then(() => commit('deleteBrand', id, customer))
  },
  selectBrand ({ commit }, brand) {
    commit('selectBrand', brand)
    commit('changeBrandLoadingState', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
