<template>
  <router-view />
</template>
<script>

export default {
  created () {
    if (process.env.NODE_ENV !== 'development') return
    this.$http.onError((e, errorCode) => {
      const title = {
        403: this.$t('Unauthorized action'),
        409: this.$t('Conflict'),
        500: this.$t('An error occurred on the server'),
        503: this.$t('Service unavailable')
      }
      this.$bvToast.toast(e, {
        title: title[errorCode] || this.$t('Server communication error'),
        variant: 'danger',
        solid: true
      })
    })
  }
}
</script>
