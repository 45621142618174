import { api } from '../api'

export function getAliases (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/aliases`)
}

export function deleteAlias (brand, userName, alias) {
  return api.delete(
    `api/v1/brands/${brand}/customers/${userName}/aliases/${alias}`
  )
}

export function checkAlias ({ brand, userName, alias }) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/aliases/${alias}/availability`
  )
}

export function addAlias (brand, userName, alias) {
  return api.post(
    `api/v1/brands/${brand}/customers/${userName}/aliases`,
    alias
  )
}
