import * as api from '@/services/mfaDevices'

const state = {
  loading: true,
  mfaDevices: [],
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setMfaDevices (state, { devices }) {
    state.mfaDevices = devices
  },
  removeMfaDevices (state, { devices }) {
    state.mfaDevices = state.mfaDevices.filter(
      ({ id }) => devices.indexOf(id) === -1
    )
  }
}
const actions = {
  getMfaDevices ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getMfaDevices(brand, userName)
      .then((devices) => {
        commit('setMfaDevices', { devices })
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  },
  deleteDevices ({ commit }, { brand, userName, devices }) {
    commit('changeLoadingState', true)
    commit('resetError')
    devices.forEach(({ providerName, id }) => {
      api
        .deleteDevice(brand, userName, providerName, id)
        .then(() => {
          commit('removeMfaDevices', { devices: [id] })
          commit('changeLoadingState', false)
        })
        .catch((error) => {
          commit('changeLoadingState', false)
          commit('setError', error)
        })
    })
  },
  deleteAllDevices ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .deleteAllDevices(brand, userName)
      .then(() => {
        commit('setMfaDevices', { devices: [] })
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
