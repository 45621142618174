const baseUrl = process.env.VUE_APP_BACKEND_SERVICE || window.CMC_BACKEND

const apiErrorCallbacks = []

const request = ({ url, method, body, params }) => {
  const urlObj = new URL(`${baseUrl}/${url}`)
  if (params) {
    for (const key in params) urlObj.searchParams.append(key, params[key])
  }
  return fetch(urlObj, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: window.store.getters['auth/getBearerToken']
    },
    cache: 'no-cache',
    credentials: 'omit',
    mode: 'cors',
    body: body ? JSON.stringify(body) : undefined
  }).then(async (response) => {
    const contentType = response.headers.get('Content-Type')
    const isJson = contentType && contentType.startsWith('application/json')
    if (response.ok) return isJson ? response.json() : response.text()
    if ([400, 403, 409, 503, 500].includes(response.status)) {
      const error = await response.json()
      console.error(error)
      apiErrorCallbacks.forEach((cb) =>
        cb(error.message || response.statusText, response.status)
      )
      throw new Error(error.message || response.statusText)
    }
  })
}
export const api = {
  get: async (url, { params } = {}) => request({ url, method: 'GET', params }),
  patch: async (url, body) => request({ url, method: 'PATCH', body }),
  put: async (url, body) => request({ url, method: 'PUT', body }),
  post: async (url, body) => request({ url, method: 'POST', body }),
  delete: async (url) => request({ url, method: 'DELETE' }),
  onError: (callback) => apiErrorCallbacks.push(callback)
}
