import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  unreadMessages: 0,
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  resetError (state) {
    state.error = undefined
  },
  setError (state, error) {
    state.error = error
  },
  setUnreadMessages (state, unreadMessages) {
    state.unreadMessages = unreadMessages
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('setUnreadMessages', 0)
    commit('resetError')
  },
  getUnreadMessages ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getUnreadMessages(brand, userName)
      .then((unreadMessages) => {
        commit('setUnreadMessages', Number(unreadMessages))
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
