import * as api from '@/services/filterRules.js'

const state = {
  loading: true,
  filterRules: [],
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setFilterRules (state, filterRules) {
    state.filterRules = filterRules
  },
  disableFilterRule (state, { brand, userName, filterId }) {
    const filterIndex = state.filterRules.findIndex(
      (item) => item.id === filterId
    )

    if (filterIndex !== -1) {
      const changedFilter = state.filterRules[filterIndex]

      changedFilter.active = false
      state.filterRules.splice(filterIndex, 1, changedFilter)
    }
  },
  deleteFilterRule (state, { brand, userName, filterId }) {
    const filterIndex = state.filterRules.findIndex(
      (item) => item.id === filterId
    )
    if (filterIndex !== -1) state.filterRules.splice(filterIndex, 1)
  }
}
const actions = {
  getFilterRules ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getFilterRules(brand, userName)
      .then((filterRules) => {
        commit('setFilterRules', filterRules)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  },
  disableFilterRule ({ commit }, { brand, userName, filterId }) {
    return api.disableFilterRule(brand, userName, filterId).then(() => {
      commit('disableFilterRule', { brand, userName, filterId })
    })
  },
  deleteFilterRule ({ commit }, { brand, userName, filterId }) {
    return api.deleteFilterRule(brand, userName, filterId).then(() => {
      commit('deleteFilterRule', { brand, userName, filterId })
    })
  },
  downloadSieveScript ({ commit }, { brand, userName }) {
    return api.downloadSieveScript(brand, userName)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
