import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  quotaStatus: '',
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setQuotaStatus (state, { status }) {
    state.quotaStatus = status
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('resetError')
    commit('setQuotaStatus', {
      status: ''
    })
  },
  getQuotaStatus ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getQuotaStatus(brand, userName)
      .then((status) => {
        commit('setQuotaStatus', { status })
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
