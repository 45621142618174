import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  mailboxSettings: {
    antivirus: false,
    spamLevel: ''
  },
  error: undefined
}

const getters = {
  antivirus: (state) => state.mailboxSettings.antivirus === 'true',
  spamLevel: (state) => state.mailboxSettings.spamLevel
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setMailboxSettings (state, mailboxSettings) {
    state.mailboxSettings = mailboxSettings
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('setMailboxSettings', {
      antivirus: false,
      spamLevel: ''
    })
    commit('resetError')
  },
  async getMailboxSettings ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    const [spamLevel, antivirus] = await Promise.all([
      api.getSpamLevel(brand, userName).catch((error) => {
        commit('setError', error)
      }),
      api.getAntivirus(brand, userName).catch((error) => {
        commit('setError', error)
      })
    ])
    commit('setMailboxSettings', { antivirus, spamLevel })
    commit('changeLoadingState', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
