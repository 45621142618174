import { api } from '../api'

export async function getGroups () {
  return api.get('api/v1/roles-groups')
}

export async function createGroup ({
  name,
  cmcRole,
  brands = [],
  members = []
}, customer = 'ox') {
  return api.post('api/v1/roles-groups', {
    customer,
    name,
    cmcRole,
    brands,
    members
  })
}

export async function getGroup (name, customer = 'ox') {
  return api.get(`api/v1/roles-groups/${customer}/${name}`)
}

export async function updateGroup ({
  id,
  name,
  cmcRole,
  brands = [],
  members = []
}, customer = 'ox') {
  return api.put(`api/v1/roles-groups/${customer}/${id}`, {
    name,
    cmcRole,
    brands,
    members
  })
}

export async function deleteGroup (id, customer = 'ox') {
  return api.delete(`api/v1/roles-groups/${customer}/${id}`)
}
