import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  permissionsChanges: [],
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setPermissionsChanges (state, permissionsChanges) {
    state.permissionsChanges = permissionsChanges
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('setPermissionsChanges', [])
    commit('resetError')
  },
  getPermissionsChanges ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getPermissionsChanges(brand, userName)
      .then((permissionsChanges) => {
        commit('setPermissionsChanges', permissionsChanges)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
