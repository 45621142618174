import { api } from '../api'

export async function getCustomers () {
  return api.get('api/v1/oxcustomers')
}

export async function createCustomer ({ id, description }) {
  return api.post('api/v1/oxcustomers', {
    id,
    description
  })
}

export async function getCustomer (id) {
  return api.get(`api/v1/oxcustomers/${id}`)
}

export async function updateCustomer ({ id, description }) {
  return api.put(`api/v1/oxcustomers/${id}`, {
    description
  })
}

export async function deleteCustomer (id) {
  return api.delete(`api/v1/oxcustomers/${id}`)
}
