import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  accesscombination: '',
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  resetError (state) {
    state.error = undefined
  },
  setError (state, error) {
    state.error = error
  },
  setAccessCombination (state, { moduleAccessCombination }) {
    state.accesscombination = moduleAccessCombination
  }
}
const actions = {
  getAccessCombination ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getAccessCombination(brand, userName)
      .then((accessComb) => {
        commit('setAccessCombination', accessComb)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
