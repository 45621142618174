import { api } from '../../api'

export async function reportMail ({ type, spamcause, b64eml, brandid, ref }) {
  const postPars = {}
  if (type) postPars.type = type
  if (spamcause) postPars.spamcause = spamcause.replace(/\s/g, '')
  if (b64eml) postPars.email = b64eml
  if (ref) postPars.ref = ref

  return api.post(`api/v1/vadesecure/${brandid}/requests`, postPars)
}

export async function decryptCause ({ encryptedcause, brandid }) {
  const postPars = {}
  // postPars.type = 'info'
  postPars.spamcause = encryptedcause.replace(/\s/g, '')

  return api.post(`api/v1/vadesecure/${brandid}/spamcause`, postPars)
}

/*
  // useful for debugging: comment real call above and use this to simulate answer

  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ id: '6086cfc66a8f0ab442087b6c', status: 'finished' })
    }, 300)
  })

  return myPromise
  */

// read operations
export async function getReports ({ searchOpts, brandid }) {
  searchOpts = searchOpts || {}

  return api.get(`api/v1/vadesecure/${brandid}/requests`, {
    params: searchOpts
  })
}

export async function getReportDetails ({ reportid, brandid }) {
  return api.get(`api/v1/vadesecure/${brandid}/requests/${reportid}`)
}

export async function getMail ({ reqid, brandid }) {
  return api.get(`api/v1/vadesecure/${brandid}/requests/${reqid}/email`)
}

export async function isItPhishing ({ url, brandid }) {
  const postPars = {}
  postPars.url = url

  return api.post(`api/v1/vadesecure/${brandid}/isitphishing`, postPars)

  /*
  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ status: 'PHISHING', location: '/api/v2/urls/aHR0cDovL3d3dy50aGlzaXNhcGhpc2hpbmd1cmwuY29t' })
    }, 300)
  })
  return myPromise
*/
  /*
  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ status: 'UNKNOWN' })
    }, 300)
  })

  return myPromise
*/
}

export async function getPhishingDetails ({ url, brandid }) {
  const postPars = {}
  postPars.url = url

  return api.post(
    `api/v1/vadesecure/${brandid}/isitphishing/details`,
    postPars
  )

  /*
  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        url: 'https://bossiun21.com/mazon/f7d0d',
        type: 'phishing',
        brand: 'amazon',
        status: 'offline',
        first_activity_time: 1651476312,
        latest_activity_time: 1651476312,
        redirections: [
          'https://bossiun21.com/mazon/f7d0d'
        ],
        screenshot: {
          status: 'not_available',
          url: ''
        }
      })
    }, 300)
  })

  return myPromise
  */
}

export async function reportPhishing ({ url, brandid, force }) {
  const postPars = {}
  postPars.url = url
  if (force && force === true) {
    postPars.force = true
  }

  return api.post(`api/v1/vadesecure/${brandid}/isitphishing/report`, postPars)
  /*
  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        error: {
          code: 422,
          message: 'Unprocessable application/json: The report was rejected for any of these reasons: The submitted URL could not be parsed, The URL is whitelisted by Vade services, You need to force the URL in order to force its report'
        }
      })
    }, 300)
  })
  return myPromise
*/
  /*
  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        success: 'The report was accepted but requires manual processing from Vade Filter team. The submitted URL is not available in the feed yet'
      })
    }, 300)
  })

  return myPromise
  */
}
