import * as api from '@/services/avas/operations.js'

const state = {
  loading: true,
  loadingerror: false,
  loadingerrormessage: '',

  urlstatus: {},
  urldetails: {},
  reportresult: {}
}

const mutations = {
  resetStatusFlags (state) {
    state.loading = false
    state.loadingerror = false
    state.loadingerrormessage = ''
  },
  cleanPhishingStatus (state) {
    state.urlstatus = {}
  },
  cleanPhishingDetails (state) {
    state.urldetails = {}
  },
  cleanReportResult (state) {
    state.reportresult = {}
  },
  changePhishingLoadingState (state, loading) {
    state.loading = loading
    if (loading === true) state.loadingerror = false
  },
  setLoadingError (state, errormsg) {
    state.loadingerror = true
    state.loadingerrormessage = errormsg
  },
  setPhishingDetails (state, phishingdetails) {
    state.urldetails = phishingdetails
  },
  setPhishingStatus (state, phishingstatus) {
    state.urlstatus = phishingstatus
  },
  setReportResult (state, reportresult) {
    state.reportresult = reportresult
  }
}

const getters = {}

const actions = {
  retrievePhishingStatus ({ commit }, { url, brandid }) {
    commit('resetStatusFlags')
    commit('cleanPhishingStatus')
    commit('changePhishingLoadingState', true)
    return api
      .isItPhishing({ url, brandid })
      .then((phishingstatus) => {
        if (!phishingstatus) {
          phishingstatus = {
            error: {
              code: '-3',
              message: 'Internal error'
            }
          }
        }
        commit('setPhishingStatus', phishingstatus)
        commit('changePhishingLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changePhishingLoadingState', false)
      })
  },

  retrievePhishingDetails ({ commit }, { url, brandid }) {
    commit('resetStatusFlags')
    commit('cleanPhishingDetails')
    commit('changePhishingLoadingState', true)
    return api
      .getPhishingDetails({ url, brandid })
      .then((phishingdetails) => {
        if (!phishingdetails) {
          phishingdetails = {
            error: {
              code: '-2',
              message: 'Internal error'
            }
          }
        }
        commit('setPhishingDetails', phishingdetails)
        commit('changePhishingLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changePhishingLoadingState', false)
      })
  },
  reportPhishing ({ commit }, { url, brandid, force }) {
    commit('resetStatusFlags')
    commit('cleanReportResult')
    commit('changePhishingLoadingState', true)
    return api
      .reportPhishing({ url, brandid, force })
      .then((reportresult) => {
        // in some cases we simple do not get result
        if (!reportresult) {
          reportresult = {
            error: {
              code: '-1',
              message: 'Internal error'
            }
          }
        }
        commit('setReportResult', reportresult)
        commit('changePhishingLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changePhishingLoadingState', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
