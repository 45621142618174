import * as api from '@/services/customers.js'

const state = {
  loading: true,
  customers: [],
  error: null
}

const getters = {
  customers: (state) => state.customers,
  getCustomerById: (state) => (id) =>
    state.customers.find((customer) => customer.id === id)
}

const mutations = {
  changeCustomerLoadingState (state, loading) {
    state.loading = loading
  },
  setCustomers (state, customers) {
    state.customers = customers.map(function (customer) {
      if (!customer.description) {
        customer.description = customer.id
      }
      return customer
    })
  },
  addCustomer (state, customer) {
    state.customers.push(customer)
  },
  updateCustomer (state, customer) {
    const cust = state.customers.find(({ id }) => customer.id === id)
    if (cust) cust.description = customer.description
  },
  deleteCustomer (state, id) {
    const index = state.customers.findIndex((i) => i.id === id)
    state.customers.splice(index, 1)
  },
  resetError (state) {
    state.error = null
  },
  setError (state, error) {
    state.error = error
  }
}

const actions = {
  getCustomers ({ commit }) {
    commit('changeCustomerLoadingState', true)
    commit('resetError')
    return api
      .getCustomers()
      .then((customers) => {
        commit('setCustomers', customers)
        commit('changeCustomerLoadingState', false)
      })
      .catch((error) => {
        commit('setError', error)
        commit('changeCustomerLoadingState', false)
      })
  },
  createCustomer ({ commit }, customer) {
    return api
      .createCustomer(customer)
      .then(({ id }) => commit('addCustomer', { ...customer, id }))
  },
  updateCustomer ({ commit }, customer) {
    return api
      .updateCustomer(customer)
      .then(() => commit('updateCustomer', customer))
  },
  deleteCustomer ({ commit }, id) {
    api.deleteCustomer(id).then(() => commit('deleteCustomer', id))
  },
  selectCustomer ({ commit }, customer) {
    commit('selectCustomer', customer)
    commit('changeCustomerLoadingState', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
