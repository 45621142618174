import { api } from '../api'

// report access
export async function getReports (brandId) {
  return api.get(`api/v1/reporting/${brandId}/reports`)
}

export async function getReport (brandId, reportId) {
  return api.get(encodeURI(`api/v1/reporting/${brandId}/reports/${reportId}`))
}
