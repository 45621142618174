import * as api from '@/services/avas/operations.js'

const state = {
  loading: true,
  loadingerror: false,
  loadingerrormessage: '',

  fpsubmissionresult: null,
  fnsubmissionresult: null
}

const mutations = {
  resetStatusFlags (state) {
    state.loading = false
    state.loadingerror = false
    state.loadingerrormessage = ''
  },
  changeLoadingState (state, loading) {
    state.loading = loading
    if (loading === true) state.loadingerror = false
  },
  setLoadingError (state, errormsg) {
    state.loadingerror = true
    state.loadingerrormessage = errormsg
  },
  resetSubmissionId (state) {
    state.fpsubmissionresult = null
    state.fnsubmissionresult = null
  },
  setSubmissionResult (state, { submissionresult, type }) {
    if (type === 'fp') {
      state.fpsubmissionresult = submissionresult
    } else {
      state.fnsubmissionresult = submissionresult
    }
  }
}

const actions = {
  reportMail ({ commit }, { type, spamcause, b64eml, brandid, ref }) {
    commit('resetStatusFlags')
    commit('resetSubmissionId')
    commit('changeLoadingState', true)
    return api
      .reportMail({ type, spamcause, b64eml, brandid, ref })
      .then((submissionresult) => {
        commit('setSubmissionResult', { submissionresult, type })
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changeLoadingState', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
