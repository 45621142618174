import * as api from '@/services/version.js'
import { version } from '../../../package'

const state = {
  loading: true,
  frontendVersion: version,
  backendVersion: 'N/A',
  ciaVersion: 'N/A'
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setVersions (state, { backendVersion, ciaVersion }) {
    state.backendVersion = backendVersion
    state.ciaVersion = ciaVersion
  }
}

const actions = {
  getVersions ({ commit }) {
    commit('changeLoadingState', true)
    api
      .getVersions()
      .then((versions) => {
        commit('setVersions', versions)
        commit('changeLoadingState', false)
      })
      .catch((error) => console.error(error))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
