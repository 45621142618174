import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  successes: null,
  failures: null,
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setLastLogins (state, lastLogins) {
    state.successes = lastLogins
  },
  setLastLoginsFailures (state, lastLoginsFailures) {
    state.failures = lastLoginsFailures
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('setLastLogins', null)
    commit('setLastLoginsFailures', null)
    commit('resetError')
  },
  getLastLogins ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getLastLogins(brand, userName)
      .then((data) => {
        commit('setLastLogins', data.successes)
        commit('setLastLoginsFailures', data.failures)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
