import * as api from '@/services/roles.js'

const state = {
  loading: true,
  roles: []
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setRoles (state, roles) {
    state.roles = roles
  }
}

const actions = {
  getRoles ({ commit }) {
    commit('changeLoadingState', true)
    api
      .getRoles()
      .then((roles) => {
        commit('setRoles', roles)
        commit('changeLoadingState', false)
      })
      .catch((error) => console.error(error))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
