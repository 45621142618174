import { api } from '../api'

// brand access
export async function getBrands () {
  return api.get('api/v1/brands')
}

export async function getParents () {
  return api.get('api/v1/brands/parents')
}

export async function createBrand (
  { name, brandDisplayName, parentBrandName, appSuiteUrl, defaultLanguage },
  customer = 'ox'
) {
  return api.post('api/v1/brands', {
    name,
    customer,
    brandDisplayName,
    parentBrandName,
    appSuiteUrl,
    defaultLanguage
  })
}

export async function getBrand (id, customer = 'ox') {
  return api.get(`api/v1/brands/${customer}/${id}`)
}

export async function updateBrand (
  { id, name, brandDisplayName, parentBrandName, appSuiteUrl, defaultLanguage },
  customer = 'ox'
) {
  // TODO: Breaks if destructuring fails
  return api.put(`api/v1/brands/${customer}/${id}`, {
    name,
    brandDisplayName,
    parentBrandName,
    appSuiteUrl,
    defaultLanguage,
    customer
  })
}

export async function deleteBrand (id, customer = 'ox') {
  return api.delete(`api/v1/brands/${customer}/${id}`)
}
