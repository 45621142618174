import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  mailbox: {
    userName: null,
    name: null,
    email: null,
    contextId: null,
    userId: null
  },
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setMailbox (state, mailbox) {
    state.mailbox = mailbox
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('resetError')
    commit('setMailbox', {
      userName: null,
      name: null,
      email: null,
      contextId: null,
      userId: null
    })
  },
  async getMailbox ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    const [sender, mailbox] = await Promise.all([
      api.getSender(brand, userName).catch((error) => {
        commit('setError', error)
      }),
      api.getMailbox(brand, userName).catch((error) => {
        commit('setError', error)
      })
    ])
    commit('setMailbox', {
      ...sender,
      ...mailbox
    })
    commit('changeLoadingState', false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
