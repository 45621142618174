import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  mailQuota: { maxQuota: 0, usedQuota: 0 },
  cloudQuota: { maxQuota: 0, usedQuota: 0 },
  quotaType: null,
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setQuota (state, { mailQuota, cloudQuota, quotaType }) {
    state.mailQuota = mailQuota
    state.cloudQuota = cloudQuota
    state.quotaType = quotaType
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('resetError')
    commit('setQuota', {
      mailQuota: { maxQuota: 0, usedQuota: 0 },
      cloudQuota: { maxQuota: 0, usedQuota: 0 },
      quotaType: null
    })
  },
  getQuota ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getQuota(brand, userName)
      .then((quota) => {
        commit('setQuota', quota)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
