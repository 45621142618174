import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import brand from './modules/brand'
import customer from './modules/customer'
import group from './modules/group'
import report from './modules/report'
import role from './modules/role'
import user from './modules/user'
import version from './modules/version'

import alias from './modules/customerCare/alias'
import antispam from './modules/customerCare/antispam'
import deliveryStatus from './modules/customerCare/deliveryStatus'
import filterRule from './modules/customerCare/filterRule'
import lastLogins from './modules/customerCare/lastLogins'
import mailAccount from './modules/customerCare/mailAccount'
import mailbox from './modules/customerCare/mailbox'
import mailboxSettings from './modules/customerCare/mailboxSettings'
import permission from './modules/customerCare/permission'
import permissionsChanges from './modules/customerCare/permissionsChanges'
import quota from './modules/customerCare/quota'
import quotaStatus from './modules/customerCare/quotaStatus'
import search from './modules/customerCare/search'
import unreadMessages from './modules/customerCare/unreadMessages'

import avascause from './modules/avas/spamcause'
import avasreport from './modules/avas/report'
import avasreportmail from './modules/avas/reportmail'
import avasphishing from './modules/avas/phishing'

import announcement from './modules/announcement'
import accesscombination from './modules/customerCare/accesscombination'
import classofservice from './modules/customerCare/classofservice'
import mfaDevices from './modules/customerCare/mfaDevices'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    accesscombination,
    alias,
    antispam,
    announcement,
    auth,
    avascause,
    avasreport,
    avasreportmail,
    avasphishing,
    brand,
    classofservice,
    customer,
    deliveryStatus,
    filterRule,
    group,
    lastLogins,
    mailAccount,
    mailbox,
    mailboxSettings,
    mfaDevices,
    permission,
    permissionsChanges,
    quota,
    report,
    quotaStatus,
    role,
    search,
    unreadMessages,
    user,
    version
  }
})
