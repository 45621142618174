import { api } from '../api'

export function findCustomer (brand, query, l) {
  const params = { search: query }
  if (l) params.l = l
  return api.get(`api/v1/brands/${brand}/customers/search`, {
    params: params
  })
}

export function getMailbox (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}`)
}

export function getPermissions (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/permissions`)
}

export function getSender (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/sender`)
}

export function getQuota (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/quota`)
}

export function getQuotaStatus (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/quotaStatus`)
}

export function getMailAccounts (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/mail-accounts`)
}

export function getUnreadMessages (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/unread-messages`
  )
}

export function getPermissionsChanges (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/permission-changes`
  )
}

export function getDeliveryStatus (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/delivery-status`
  )
}

export function getLastLogins (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/last-login`)
}

export function putPermissions (brand, userName, permissions, reason) {
  return api.put(`api/v1/brands/${brand}/customers/${userName}/permissions`, {
    permissions,
    reason
  })
}

export function getAllowed (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/allowed-addresses`
  )
}

export function getBlocked (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/blocked-addresses`
  )
}

export function deleteAllowed (brand, userName, entry) {
  return api.delete(
    `api/v1/brands/${brand}/customers/${userName}/antispam/allowlist/${entry}`
  )
}

export function deleteBlocked (brand, userName, entry) {
  return api.delete(
    `api/v1/brands/${brand}/customers/${userName}/antispam/blocklist/${entry}`
  )
}

export function getClassOfService (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/class-of-service`
  )
}

export function getAccessCombination (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/access-combination`
  )
}

/* Mailbox settings */
export function getSpamLevel (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/mailbox-settings/spamlevel`
  )
}

export function getAntivirus (brand, userName) {
  return api.get(
    `api/v1/brands/${brand}/customers/${userName}/mailbox-settings/antivirus`
  )
}

export function putSpamLevel (brand, userName, spamlevel) {
  return api.put(
    `api/v1/brands/${brand}/customers/${userName}/mailbox-settings/spamlevel`,
    { spamlevel }
  )
}

export function putAntivirus (brand, userName, antivirus) {
  return api.put(
    `api/v1/brands/${brand}/customers/${userName}/mailbox-settings/antivirus`,
    { antivirus }
  )
}
