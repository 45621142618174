import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  statusCode: 0,
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setDeliveryStatus (state, statusCode) {
    state.statusCode = statusCode
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeLoadingState', true)
    commit('setDeliveryStatus', '')
    commit('resetError')
  },
  getDeliveryStatus ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getDeliveryStatus(brand, userName)
      .then((statusCode) => {
        commit('setDeliveryStatus', statusCode)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
