import * as api from '@/services/reports.js'

const state = {
  loading: true,
  reports: [],
  rawReport: ''
}

const getters = {
  reports: (state) => state.reports,
  getRAWReport: (state) => state.rawReport
}

const mutations = {
  changeReportsLoadingState (state, loading) {
    state.loading = loading
  },
  setReports (state, { reports }) {
    state.reports = reports.map((rep) => ({ id: rep }))
  },
  setRAWReport (state, rawReport) {
    state.rawReport = rawReport
  },
  resetReports (state) {
    state.reports = []
  }
}

const actions = {
  getReports ({ commit }, brandId) {
    commit('changeReportsLoadingState', true)
    commit('resetReports')
    return api
      .getReports(brandId)
      .then((reports) => {
        commit('setReports', reports)
        commit('changeReportsLoadingState', false)
      })
      .catch((error) => {
        commit('changeReportsLoadingState', false)
        console.error(error)
      })
  },
  downloadRAWReport ({ commit }, { brandId, reportId }) {
    return api
      .getReport(brandId, reportId)
      .then((rawReport) => {
        commit('setRAWReport', rawReport)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
