import * as api from '@/services/users.js'

const state = {
  loading: true,
  users: [],
  me: {},
  error: undefined
}

const getters = {
  users: (state) => [...state.users, state.me],
  getUserById: (state, getters) => (id) =>
    getters.users.find((user) => user.id === id || user.email === id),
  getUserCustomer: (state, getters) => (id) =>
    getters.getUserById(id).customer || undefined,
  loggedInUserCustomer: ({ me }) => me.customer || undefined,
  canEdit: (state, getters) => (id, roleName) => {
    const user = getters.getUserById(id)
    if (!user) return false
    if (roleName === 'OXAdmin') return true
    if (roleName === 'BrandAdmin') {
      return !user.roles.some((role) => ['OXAdmin'].includes(role.name))
    }
    if (roleName.includes('SupportManager')) {
      return !user.roles.some((role) =>
        ['OXAdmin', 'BrandAdmin'].includes(role.name)
      )
    }
    return false
  }
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setMe (state, user) {
    state.me = user
  },
  setUsers (state, users) {
    state.users = users
  },
  deleteUser (state, id) {
    const index = state.users.findIndex((i) => i.id === id)
    if (index !== -1) state.users.splice(index, 1)
  },
  addUser (state, user) {
    state.users.push(user)
  }
}

const actions = {
  getUsers ({ commit }) {
    commit('changeLoadingState', true)
    return api
      .getUsers()
      .then((users) => {
        commit('setUsers', users)
        commit('changeLoadingState', false)
      })
      .catch((error) => console.error(error))
  },
  getUser ({ commit, getters }, id) {
    const customer = getters.getUserCustomer(id)
    return api.getUser(id, customer).then((user) => {
      commit('deleteUser', id)
      commit('addUser', { ...user, id })
    })
  },
  getMe ({ commit }) {
    return api.getMe().then(({ user, brands }) => {
      commit('setMe', { ...user, brands })
    })
  },
  deleteUser ({ commit, getters }, id) {
    const customer = getters.getUserCustomer(id)
    return api.deleteUser(id, customer).then(() => commit('deleteUser', id))
  },
  createUser ({ commit }, user) {
    return api.createUser(user).then((id) => {
      delete user.password
      commit('addUser', { ...user, id })
    })
  },
  updateUser ({ getters }, user) {
    const customer = getters.getUserCustomer(user.id)
    return api.updateUser(user, customer)
  },
  updateProfile ({ dispatch, getters }, { id, firstName, lastName, email }) {
    const user = getters.getUserById(id)
    return dispatch('updateUser', { ...user, firstName, lastName, email })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
