import { api } from '../api'

export function getMfaDevices (brand, userName) {
  return api.get(`api/v1/brands/${brand}/customers/${userName}/mfa-devices`)
}

export function deleteDevice (brand, userName, provider, deviceId) {
  return api.delete(`api/v1/brands/${brand}/customers/${userName}/mfa-devices/${provider}/${deviceId}`)
}

export function deleteAllDevices (brand, userName) {
  return api.delete(`api/v1/brands/${brand}/customers/${userName}/mfa-devices/all`)
}
