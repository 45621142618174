import * as api from '@/services/aliases.js'

const state = {
  loading: true,
  aliases: [],
  error: undefined
}

const getters = {
  getWrappedAliases:
    ({ aliases }) =>
      () =>
        aliases.map((alias) => {
          return { alias }
        })
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setAliases (state, aliases) {
    state.aliases = aliases
  },
  addAlias (state, alias) {
    state.aliases.push(alias)
  },
  deleteAlias (state, alias) {
    const index = state.aliases.findIndex((item) => item === alias)
    state.aliases.splice(index, 1)
  }
}

const actions = {
  getAliases ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getAliases(brand, userName)
      .then((aliases) => {
        commit('setAliases', aliases)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  },
  addAlias ({ commit }, { brand, userName, alias }) {
    api
      .addAlias(brand, userName, alias)
      .then(() => {
        commit('addAlias', alias)
      })
      .catch((error) => console.error(error))
  },
  deleteAlias ({ commit }, { brand, userName, alias }) {
    api
      .deleteAlias(brand, userName, alias)
      .then(() => {
        commit('deleteAlias', alias)
      })
      .catch((error) => console.error(error))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
