import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import '@/styles/custom.scss'

import {
  AlertPlugin,
  BadgePlugin,
  BIconCheck2,
  BIconExclamationCircleFill,
  BIconCaretDown,
  BIconCaretDownFill,
  BIconCaretRight,
  BIconCaretRightFill,
  BIconInfo,
  BIconPause,
  BIconToggleOn,
  BIconToggleOff,
  BIconPlay,
  BIconDash,
  BIconDownload,
  BIconEye,
  BIconEyeSlash,
  BIconInfoCircle,
  BIconInfoCircleFill,
  BIconPauseCircle,
  BIconPencilSquare,
  BIconPlayCircle,
  BIconStopCircle,
  BIconThreeDotsVertical,
  BIconTrash,
  BIconDashCircle,
  BIconX,
  ButtonPlugin,
  CardPlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  PaginationPlugin,
  ProgressPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  OverlayPlugin
} from 'bootstrap-vue'
import store from '@/store'
import { authenticate } from '@/services/keycloak'
import { loadInitialData } from '@/services/bootstrap'
import { api } from '@/api'

// import self hosted tinymce
import 'tinymce'
/* Required TinyMCE components */
import 'tinymce/icons/default/icons.min.js'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/models/dom/model.min.js'

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.js'

/* Import plugins */
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/table'
import 'tinymce/plugins/code'
import 'tinymce/plugins/help'
import 'tinymce/plugins/wordcount'
// import 'tinymce/plugins/advlist';
// import 'tinymce/plugins/code';
// import 'tinymce/plugins/emoticons';
// import 'tinymce/plugins/emoticons/js/emojis';
// import 'tinymce/plugins/link';
// import 'tinymce/plugins/lists';
// import 'tinymce/plugins/table';

/* content UI CSS is required */
import 'tinymce/skins/ui/oxide/content.js'

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import 'tinymce/skins/content/default/content.js'

window.store = store

Vue.use(AlertPlugin)
Vue.use(BadgePlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.use(PaginationPlugin)
Vue.use(ProgressPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(ToastPlugin)
Vue.use(OverlayPlugin)

Vue.component('BIconCaretDown', BIconCaretDown)
Vue.component('BIconCaretDownFill', BIconCaretDownFill)
Vue.component('BIconCaretRight', BIconCaretRight)
Vue.component('BIconCaretRightFill', BIconCaretRightFill)
Vue.component('BIconToggleOn', BIconToggleOn)
Vue.component('BIconToggleOff', BIconToggleOff)
Vue.component('BIconInfo', BIconInfo)
Vue.component('BIconPause', BIconPause)
Vue.component('BIconPlay', BIconPlay)
Vue.component('BIconDash', BIconDash)
Vue.component('BIconDownload', BIconDownload)
Vue.component('BIconCheck2', BIconCheck2)
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill)
Vue.component('BIconEye', BIconEye)
Vue.component('BIconEyeSlash', BIconEyeSlash)
Vue.component('BIconInfoCircle', BIconInfoCircle)
Vue.component('BIconInfoCircleFill', BIconInfoCircleFill)
Vue.component('BIconPencilSquare', BIconPencilSquare)
Vue.component('BIconPauseCircle', BIconPauseCircle)
Vue.component('BIconPlayCircle', BIconPlayCircle)
Vue.component('BIconStopCircle', BIconStopCircle)
Vue.component('BIconThreeDotsVertical', BIconThreeDotsVertical)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconDashCircle', BIconDashCircle)
Vue.component('BIconX', BIconX)

Vue.config.productionTip = false

Vue.prototype.$http = api

authenticate()
  .then(loadInitialData)
  .then(() => {
    new Vue({
      router,
      i18n,
      store,
      render: (h) => h(App)
    }).$mount('#app')
  })

window.Vue = Vue
