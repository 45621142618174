import { api } from '../api'

const apiVersion = 'api/v1'
const baseEndpoint = (brandId) =>
  `${apiVersion}/brands/${brandId}/announcements`

export async function getAnnouncements (brandId) {
  return api.get(baseEndpoint(brandId))
}

export async function createAnnouncement ({
  brands,
  msgJsonObject,
  name,
  startDatetime,
  endDatetime,
  enabled,
  discardable,
  brandId,
  ownerBrand,
  timezone
}) {
  return api.post(baseEndpoint(brandId), {
    brands,
    discardable,
    enabled,
    msgJsonObject,
    name,
    startDatetime,
    endDatetime,
    ownerBrand,
    timezone
  })
}

export async function getAnnouncement (id, brandId) {
  return api.get(`${baseEndpoint(brandId)}/${id}`)
}

export async function updateAnnouncement ({
  id,
  brands,
  msgJsonObject,
  name,
  startDatetime,
  endDatetime,
  enabled,
  discardable,
  brandId,
  ownerBrand,
  timezone,
  version
}) {
  return api.put(`${baseEndpoint(brandId)}/${id}`, {
    id,
    brands,
    discardable,
    enabled,
    msgJsonObject,
    name,
    startDatetime,
    endDatetime,
    ownerBrand,
    timezone,
    version
  })
}

export async function deleteAnnouncement (id, brandId) {
  return api.delete(`${baseEndpoint(brandId)}/${id}`)
}
