import * as api from '@/services/mailboxes.js'

const state = {
  loadingAllowed: true,
  loadingBlocked: true,
  blocked: [],
  allowed: [],
  error: undefined
}

const mutations = {
  changeAllowedLoadingState (state, loading) {
    state.loadingAllowed = loading
  },
  changeBlockedLoadingState (state, loading) {
    state.loadingBlocked = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  setAllowed (state, allowed) {
    state.allowed = allowed
  },
  setBlocked (state, blocked) {
    state.blocked = blocked
  },
  deleteBlocked (state, entry) {
    const index = state.blocked.findIndex((i) => i === entry)
    if (index !== -1) state.blocked.splice(index, 1)
  },
  deleteAllowed (state, entry) {
    const index = state.allowed.findIndex((i) => i === entry)
    if (index !== -1) state.allowed.splice(index, 1)
  }
}
const actions = {
  reset ({ commit }) {
    commit('changeAllowedLoadingState', true)
    commit('changeBlockedLoadingState', true)
    commit('setAllowed', [])
    commit('setBlocked', [])
    commit('resetError')
  },
  getAntispam ({ commit }, { brand, userName }) {
    commit('resetError')
    api
      .getAllowed(brand, userName)
      .then((allowed) => {
        commit('changeAllowedLoadingState', true)
        commit('setAllowed', allowed)
        commit('changeAllowedLoadingState', false)
      })
      .catch((error) => {
        commit('changeAllowedLoadingState', false)
        commit('changeBlockedLoadingState', false)
        commit('setError', error)
      })
    api
      .getBlocked(brand, userName)
      .then((blocked) => {
        commit('changeBlockedLoadingState', true)
        commit('setBlocked', blocked)
        commit('changeBlockedLoadingState', false)
      })
      .catch((error) => {
        commit('changeAllowedLoadingState', false)
        commit('changeBlockedLoadingState', false)
        commit('setError', error)
      })
  },
  deleteBlocked ({ commit }, { brand, userName, entry }) {
    return api
      .deleteBlocked(brand, userName, entry)
      .then(() => {
        commit('deleteBlocked', entry)
      })
      .catch((error) => console.error(error))
  },
  deleteAllowed ({ commit }, { brand, userName, entry }) {
    return api
      .deleteAllowed(brand, userName, entry)
      .then(() => {
        commit('deleteAllowed', entry)
      })
      .catch((error) => console.error(error))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
