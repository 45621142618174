import * as api from '@/services/announcements.js'

const state = {
  loading: true,
  announcements: [],
  error: null
}

const getters = {
  announcements: (state) => state.announcements,
  getAnnouncementById: (state) => (id) =>
    state.announcements.find((announcement) => `${announcement.id}` === `${id}`)
}

const mutations = {
  changeAnnouncementLoadingState (state, loading) {
    state.loading = loading
  },
  setAnnouncements (state, announcements) {
    state.announcements = announcements
  },
  addAnnouncement (state, announcement) {
    state.announcements.push(announcement)
  },
  updateAnnouncement (state, announcement) {
    const index = state.announcements.findIndex(
      ({ id }) => `${announcement.id}` === `${id}`
    )
    if (index !== -1) state.announcements[index] = announcement
  },
  deleteAnnouncement (state, id) {
    const index = state.announcements.findIndex((i) => `${i.id}` === `${id}`)
    state.announcements.splice(index, 1)
  },
  resetError (state) {
    state.error = null
  },
  setError (state, error) {
    state.error = error
  }
}

const actions = {
  getAnnouncements ({ commit }, brandId) {
    commit('changeAnnouncementLoadingState', true)
    commit('resetError')
    return api
      .getAnnouncements(brandId)
      .then((announcements) => {
        commit('setAnnouncements', announcements)
        commit('changeAnnouncementLoadingState', false)
      })
      .catch((error) => {
        commit('setError', error)
        commit('changeAnnouncementLoadingState', false)
      })
  },
  createAnnouncement ({ commit }, { announcement, brandId }) {
    return api
      .createAnnouncement({ ...announcement, brandId })
      .then(({ id }) => commit('addAnnouncement', { ...announcement, id }))
  },
  updateAnnouncement ({ commit }, { announcement, brandId }) {
    return api
      .updateAnnouncement({ ...announcement, brandId })
      .then(() => commit('updateAnnouncement', announcement))
  },
  deleteAnnouncement ({ commit }, { id, brandId }) {
    api
      .deleteAnnouncement(id, brandId)
      .then(() => commit('deleteAnnouncement', id))
  },
  selectAnnouncement ({ commit }, announcement) {
    commit('selectAnnouncement', announcement)
    commit('changeAnnouncementLoadingState', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
