import * as api from '@/services/mailboxes.js'

const state = {
  loading: false,
  hits: [],
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  setHits (state, hits) {
    state.hits = hits
  }
}
const actions = {
  findCustomer ({ commit }, { brand, userName, l }) {
    const mapHits = (hit) => {
      if (typeof hit.alias !== 'string') {
        hit.alias = hit.alias.join(', ')
      }
      return hit
    }

    commit('changeLoadingState', true)
    return api
      .findCustomer(brand, userName, l)
      .then((hits) => {
        let { accounts, forwards } = hits

        if (accounts && forwards) {
          accounts = accounts.map(mapHits) || []
          forwards = forwards.map(mapHits) || []
        }
        commit('setHits', accounts.concat(forwards))
        commit('changeLoadingState', false)
        return { accounts, forwards }
      })
      .catch((error) => console.error(error))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
