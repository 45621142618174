// Define roles needed for accessing each feature
export const rolesForFeature = {
  administration: {
    users: [
      'OXAdmin',
      'OXSupportManager',
      'OXSupportAgent',
      'BrandAdmin',
      'BrandSupportManager'
    ],
    brands: [
      'OXAdmin',
      'OXSupportManager',
      'OXSupportAgent',
      'BrandAdmin',
      'BrandSupportManager',
      'BrandSupportAgent'
    ],
    groups: [
      'OXAdmin',
      'OXSupportManager',
      'OXSupportAgent',
      'BrandAdmin',
      'BrandSupportManager'
    ],
    customers: ['OXAdmin']
  },
  announcements: ['OXAdmin', 'BrandAdmin', 'Announcement'],
  avas: ['OXAdmin', 'Avas'],
  customerCare: [
    'OXAdmin',
    'OXSupportManager',
    'OXSupportAgent',
    'BrandAdmin',
    'BrandSupportManager',
    'BrandSupportAgent',
    'SupportTrainee'
  ],
  dashboards: ['OXAdmin', 'BrandAdmin', 'Reporting'],
  metrics: ['OXAdmin', 'BrandAdmin', 'Metrics'],
  reports: ['OXAdmin', 'BrandAdmin', 'Reporting'],
  profile: []
}

export default {
  rolesForFeature
}
