import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import { rolesForFeature } from './shared/roles'

Vue.use(VueRouter)

// TODO: put chunking into seperate file

const Page = () => import(/* webpackChunkName: "default" */ '@/views/Page.vue')
const LandingPage = () =>
  import(/* webpackChunkName: "default" */ '@/views/LandingPage.vue')

const CustomerCare = () =>
  import(/* webpackChunkName: "customercare" */ '@/views/CustomerCare.vue')
const AdminBrands = () =>
  import(/* webpackChunkName: "adminbrands" */ '@/views/Brands.vue')
const AdminBrandsEdit = () =>
  import(/* webpackChunkName: "adminbrands" */ '@/views/BrandsEdit.vue')
const AdminCustomers = () =>
  import(/* webpackChunkName: "admincustomers" */ '@/views/Customers.vue')
const AdminCustomersEdit = () =>
  import(/* webpackChunkName: "admincustomers" */ '@/views/CustomersEdit.vue')
const AdminUsers = () =>
  import(/* webpackChunkName: "adminusers" */ '@/views/Users.vue')
const AdminUsersEdit = () =>
  import(/* webpackChunkName: "adminusers" */ '@/views/UsersEdit.vue')
const AdminGroups = () =>
  import(/* webpackChunkName: "admingroups" */ '@/views/Groups.vue')
const AdminGroupsEdit = () =>
  import(/* webpackChunkName: "admingroups" */ '@/views/GroupsEdit.vue')
const Profile = () =>
  import(/* webpackChunkName: "default" */ '@/views/Profile.vue')
const Dashboards = () =>
  import(/* webpackChunkName: "dashboards" */ '@/views/Dashboards.vue')
const Reports = () =>
  import(/* webpackChunkName: "reports" */ '@/views/reports/ReportsList.vue')
const AvasReports = () =>
  import(/* webpackChunkName: "avasreports" */ '@/views/avas/AvasReports.vue')
const AvasSpamcause = () =>
  import(/* webpackChunkName: "avasreports" */ '@/views/avas/AvasSpamcause.vue')
const AvasReportMail = () =>
  import(
    /* webpackChunkName: "avasreports" */ '@/views/avas/AvasReportMail.vue'
  )
const AvasFindById = () =>
  import(/* webpackChunkName: "avasreports" */ '@/views/avas/AvasFindById.vue')

const AvasReportPhishing = () =>
  import(
    /* webpackChunkName: "avasreports" */ '@/views/avas/AvasReportPhishing.vue'
  )

const Announcements = () =>
  import(/* webpackChunkName: "announcements" */ '@/views/Announcements.vue')

const AnnouncementsEdit = () =>
  import(/* webpackChunkName: "announcements" */ '@/views/AnnouncementsEdit.vue')

const AvasItems = [
  {
    path: '/avas/spamcause',
    roles: rolesForFeature.avas,
    title: 'Decrypt Spamcause'
  },
  {
    path: '/avas/reportmail',
    roles: rolesForFeature.avas,
    title: 'Report mail as FP/FN'
  },
  {
    path: '/avas/reports',
    roles: rolesForFeature.avas,
    title: 'Report History'
  },
  {
    path: '/avas/findbyid',
    roles: rolesForFeature.avas,
    title: 'Find by Id'
  },
  {
    path: '/avas/reportphishing',
    roles: rolesForFeature.avas,
    title: 'Report URL as Phishing'
  }
]

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/landingpage',
      meta: { roles: [] }
    },
    {
      path: '/landingpage',
      component: LandingPage,
      props: { title: 'Landing Page' },
      meta: { roles: [] }
    },
    {
      path: '/:brand/dashboards',
      component: Dashboards,
      props: { title: 'Dashboards', dashboardName: 'reporting' },
      meta: { roles: rolesForFeature.dashboards }
    },
    {
      path: '/:brand/metrics',
      component: Dashboards,
      props: { title: 'Metrics', dashboardName: 'metrics' },
      meta: { roles: rolesForFeature.metrics }
    },
    {
      path: '/profile',
      component: Profile,
      props: { title: 'Profile' },
      meta: { roles: rolesForFeature.profile }
    },
    {
      path: '/customercare',
      component: CustomerCare,
      props: { title: 'Customer Care' },
      meta: { roles: rolesForFeature.customerCare }
    },
    {
      path: '/:brand/customercare',
      component: CustomerCare,
      props: { title: 'Customer Care' },
      meta: { roles: rolesForFeature.customerCare }
    },
    {
      path: '/:brand/customercare/:userName',
      component: CustomerCare,
      props: { title: 'Customer Care' },
      meta: { roles: rolesForFeature.customerCare }
    },
    {
      path: '/administration',
      component: Page,
      props: { title: 'Administration', hasSidebar: true },
      meta: { roles: Object.values(rolesForFeature.administration).flat() },
      async beforeEnter (to, from, next) {
        const hasRole = store.getters['auth/hasRole']
        const { users, brands, groups, customers } =
          rolesForFeature.administration
        const hasUsersRole = users.some((r) => hasRole(r))
        const hasBrandsRole = brands.some((r) => hasRole(r))
        const hasGroupsRole = groups.some((r) => hasRole(r))
        const hasCustomersRole = customers.some((r) => hasRole(r))
        if (hasUsersRole) return next('/administration/users')
        if (hasBrandsRole) return next('/administration/brands')
        if (hasGroupsRole) return next('/administration/groups')
        if (hasCustomersRole) return next('/administration/customers')
      }
    },
    {
      path: '/administration/users',
      component: AdminUsers,
      props: { title: 'User Management', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.users }
    },
    {
      path: '/administration/user/:id',
      component: AdminUsersEdit,
      props: { title: 'User Management', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.users },
      async beforeEnter (to, from, next) {
        await store.dispatch('user/getUsers')
        const isEditable = store.getters['auth/getRoleNames'].some((roleName) =>
          store.getters['user/canEdit'](to.params.id, roleName)
        )
        if (isEditable || to.params.id === 'new') next()
        else next('/administration/users')
      }
    },
    {
      path: '/administration',
      redirect: 'administration/brands',
      props: { title: 'Brand Configuration', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.brands }
    },
    {
      path: '/administration/brands',
      component: AdminBrands,
      props: { title: 'Brand Configuration', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.brands }
    },
    {
      path: '/administration/brand/:customer/:id',
      component: AdminBrandsEdit,
      props: { title: 'Brand Configuration', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.brands }
    },
    {
      path: '/administration/customers',
      component: AdminCustomers,
      props: { title: 'Customers Management', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.customers }
    },
    {
      path: '/administration/customer/:id',
      component: AdminCustomersEdit,
      props: { title: 'Customers Management', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.customers }
    },
    {
      path: '/administration/groups',
      component: AdminGroups,
      props: { title: 'Group Configuration', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.groups }
    },
    {
      path: '/administration/group/:customer/:id',
      component: AdminGroupsEdit,
      props: { title: 'Group Configuration', hasSidebar: true },
      meta: { roles: rolesForFeature.administration.groups }
    },
    {
      path: '/avas',
      redirect: '/avas/spamcause',
      props: {
        title: 'AVAS',
        hasSidebar: true,
        sidebarItems: AvasItems
      },
      meta: { roles: rolesForFeature.avas }
    },
    {
      path: '/avas/spamcause',
      component: AvasSpamcause,
      props: {
        title: 'Decrypt Spamcause',
        hasSidebar: true,
        sidebarItems: AvasItems
      },
      meta: { roles: rolesForFeature.avas }
    },
    {
      path: '/avas/reportmail',
      component: AvasReportMail,
      props: {
        title: 'Report mail as false positive or false negative',
        hasSidebar: true,
        sidebarItems: AvasItems
      },
      meta: { roles: rolesForFeature.avas }
    },
    {
      path: '/avas/reports',
      component: AvasReports,
      props: {
        title: 'Report History',
        hasSidebar: true,
        sidebarItems: AvasItems
      },
      meta: { roles: rolesForFeature.avas }
    },
    {
      path: '/avas/findbyid',
      component: AvasFindById,
      props: {
        title: 'Find request details by Id',
        hasSidebar: true,
        sidebarItems: AvasItems
      },
      meta: { roles: rolesForFeature.avas }
    },
    {
      path: '/avas/reportphishing',
      component: AvasReportPhishing,
      props: {
        title: 'Check and report phishing URL',
        hasSidebar: true,
        sidebarItems: AvasItems
      },
      meta: { roles: rolesForFeature.avas }
    },
    {
      path: '/:brand/reports',
      component: Reports,
      props: {
        title: 'List of your reports',
        hasSidebar: false
      },
      meta: { roles: rolesForFeature.reports }
    },
    {
      path: '/:brand/announcements',
      component: Announcements,
      props: { title: 'Announcements' },
      meta: { roles: rolesForFeature.announcements }
    },
    {
      path: '/:brand/announcements/:id',
      component: AnnouncementsEdit,
      props: { title: 'Announcements' },
      meta: { roles: rolesForFeature.announcements }
    },
    {
      path: '*',
      component: Page,
      props: { title: '404 - OOPS! Something went wrong here.' },
      meta: { roles: [] }
    }
  ]
})

Router.beforeEach((to, from, next) => {
  const roles = store.state.auth.roles
  const routeRoles = to.meta.roles
  if (!routeRoles.length) next()
  else {
    const hasRole = routeRoles.some((s) => roles.includes(s))
    if (!hasRole) next('/')
    else next()
  }
})

Router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    const [matched] = to.matched
    const { title } = matched.props.default
    document.title = `${title ? title + ' - ' : ''}OX Cloud Management Center`
  })
})

export default Router
