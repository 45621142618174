import store from '@/store'
import * as Keycloak from 'keycloak-js'

const keycloak = Keycloak({
  url: `${process.env.VUE_APP_KEYCLOAK_SERVICE || window.CMC_KEYCLOAK}/auth`,
  realm: process.env.VUE_APP_KEYCLOAK_REALM || window.CMC_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT || window.CMC_KEYCLOAK_CLIENT
})

const refreshToken = (seconds) => {
  const refresh = () => {
    keycloak
      .updateToken(45)
      .then(async (refreshed) => {
        const { token, tokenParsed, timeSkew } = keycloak
        if (refreshed) {
          // console.log('Refreshing Token')
          await store.dispatch('auth/login', { token, tokenParsed })
        } else {
          console.warn(
            'Token not refreshed, valid for ' +
              Math.round(
                tokenParsed.exp + timeSkew - new Date().getTime() / 1000
              ) +
              ' seconds'
          )
        }
      })
      .catch((err) => {
        console.error('Failed to refresh token', err)
      })
    setTimeout(refresh, seconds * 1000)
  }
  refresh()
}

export async function logout () {
  const gfSsoLogoutUrl =
    process.env.VUE_APP_GF_SSO_LOGOUT_URL || window.CMC_GF_SSO_LOGOUT_URL

  store.dispatch('auth/logout')
  keycloak.logout(
    gfSsoLogoutUrl !== ''
      ? {
          redirectUri: gfSsoLogoutUrl
        }
      : {}
  )
}

export async function authenticate () {
  try {
    return keycloak
      .init({
        onLoad: 'login-required',
        promiseType: 'native',
        checkLoginIframe: process.env.VUE_APP_KEYCLOAK_IFRAME === 'true'
      })
      .then(async (auth) => {
        if (!auth) window.location.reload()
        else console.log('Authenticated')

        const { token, tokenParsed } = keycloak

        await store.dispatch('auth/login', { token, tokenParsed })
        refreshToken(30)
      })
  } catch (err) {
    console.error('Authenticated Failed', err)
  }
}
