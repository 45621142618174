import { api } from '../api'

function isTrue (value) {
  if (typeof value === 'string') return value === 'true'
  return value
}

// user access
export async function getUsers () {
  return api.get('api/v1/users')
}

export async function createUser ({
  username,
  firstName,
  lastName,
  password,
  email,
  enabled,
  roles = [],
  rolesGroups = [],
  brands = [],
  customer
}) {
  return api.post('api/v1/users', {
    username,
    firstName,
    lastName,
    password,
    email,
    enabled: isTrue(enabled),
    roles,
    rolesGroups,
    brands,
    customer
  })
}

export async function getUser (id, customer = 'ox') {
  return api.get(`api/v1/users/${customer}/${id}`)
}

export async function getMe () {
  return api.get('api/v1/users/me')
}

export async function updateUser (
  {
    id,
    username,
    firstName,
    lastName,
    password,
    email,
    enabled,
    roles = [],
    rolesGroups = [],
    brands = []
  },
  customer = 'ox'
) {
  return api.put(`api/v1/users/${customer}/${id}`, {
    username,
    firstName,
    lastName,
    password,
    email,
    enabled: isTrue(enabled),
    roles,
    rolesGroups,
    brands
  })
}

export async function deleteUser (id, customer = 'ox') {
  return api.delete(`api/v1/users/${customer}/${id}`)
}
