import store from '@/store'

export async function loadInitialData () {
  try {
    await store.dispatch('user/getMe')
    const currentUserID = store.getters['auth/currentUserId']
    const { brands: userAssignedBrands, roles } =
      store.getters['user/getUserById'](currentUserID)
    await store.dispatch('brand/getBrands', userAssignedBrands)
    await store.dispatch('auth/setRoles', { roles })
    await store.dispatch('version/getVersions')
    await store.dispatch('customer/getCustomers')
  } catch (e) {
    console.log('loadinitdata failed:', e)
  }
}
