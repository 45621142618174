import * as api from '@/services/avas/operations.js'

const state = {
  loading: true,
  loadingerror: false,
  loadingerrormessage: '',

  decryptedinfo: null
}

const mutations = {
  resetStatusFlags (state) {
    state.loading = false
    state.loadingerror = false
    state.loadingerrormessage = ''
  },
  changeLoadingState (state, loading) {
    state.loading = loading
    if (loading === true) state.loadingerror = false
  },
  setLoadingError (state, errormsg) {
    state.loadingerror = true
    state.loadingerrormessage = errormsg
  },
  setdecryptedinfo (state, { causedetails }) {
    state.decryptedinfo = causedetails
  }
}

const actions = {
  decryptCause ({ commit }, { encryptedcause, brandid }) {
    commit('resetStatusFlags')
    commit('changeLoadingState', true)
    return api
      .decryptCause({ encryptedcause, brandid })
      .then(causedetails => {
        commit('setdecryptedinfo', { causedetails })
        commit('changeLoadingState', false)
      })
      .catch(error => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changeLoadingState', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
