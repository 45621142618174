import * as api from '@/services/mailboxes.js'

const state = {
  loading: true,
  permissions: [],
  showPermissionsChanges: false,
  error: undefined
}

const mutations = {
  changeLoadingState (state, loading) {
    state.loading = loading
  },
  setError (state, error) {
    state.error = error
  },
  resetError (state) {
    state.error = undefined
  },
  changeShowPermissionsChangesState (state) {
    state.showPermissionsChanges = !state.showPermissionsChanges
  },
  setPermissions (state, permissions) {
    state.permissions = permissions
  }
}
const actions = {
  getPermissions ({ commit }, { brand, userName }) {
    commit('changeLoadingState', true)
    commit('resetError')
    api
      .getPermissions(brand, userName)
      .then((permissions) => {
        commit('setPermissions', permissions.enabled)
        commit('changeLoadingState', false)
      })
      .catch((error) => {
        commit('changeLoadingState', false)
        commit('setError', error)
      })
  },

  toggleShowPermissionsChanges ({ dispatch, commit }, { brand, userName }) {
    commit('changeShowPermissionsChangesState')
    if (state.showPermissionsChanges) {
      dispatch(
        'permissionsChanges/getPermissionsChanges',
        { brand, userName },
        { root: true }
      )
    } else dispatch('permissionsChanges/reset', null, { root: true })
  },

  async setPermissions (
    { dispatch, commit },
    { brand, userName, permissions, reason }
  ) {
    await api.putPermissions(brand, userName, permissions, reason)
    commit('setPermissions', permissions)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
