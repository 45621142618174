const state = {
  token: false,
  tokenParsed: null,
  roles: []
}

const getters = {
  isOXAdmin: (state, getters) => getters.hasRole('OXAdmin'),
  isReadOnly: (state, getters) => getters.hasRole('SupportTrainee'),
  isAuthenticated: ({ token }) => !!token,
  getBearerToken: ({ token }) => !token ? false : `Bearer ${token}`,
  getPreferredUsername: ({ tokenParsed }) => tokenParsed ? tokenParsed.preferred_username : '',
  getEmail: ({ tokenParsed }) => tokenParsed ? tokenParsed.email : '',
  getRoleNames: ({ roles }) => roles || [],
  hasRole: ({ roles }) => (role) => !!roles.includes(role),
  currentUserId: ({ tokenParsed }) => tokenParsed ? tokenParsed.preferred_username : null
}

const mutations = {
  setRoles (state, roles) {
    state.roles = roles || []
  },
  setToken (state, token) {
    state.token = token || null
  },
  setTokenParsed (state, tokenParsed) {
    state.tokenParsed = tokenParsed || null
  }
}

const actions = {
  setRoles ({ commit }, { roles }) {
    commit('setRoles', roles)
  },
  login ({ commit }, { token, tokenParsed }) {
    commit('setToken', token)
    commit('setTokenParsed', tokenParsed)
    return Promise.resolve()
  },
  logout ({ commit }) {
    localStorage.removeItem('brand')
    commit('setToken')
    commit('setTokenParsed')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
