import * as api from '@/services/avas/operations.js'

const state = {
  loading: true,
  loadingerror: false,
  loadingerrormessage: '',

  reports: [],
  page_size: 10,

  page_next_offset: '',
  page_current_offset: '',

  rawdata: {},
  reportdetails: {},
  rawemail: ''
}

const mutations = {
  resetStatusFlags (state) {
    state.loading = false
    state.loadingerror = false
    state.loadingerrormessage = ''
    state.rawemail = ''
  },
  changeReportLoadingState (state, loading) {
    state.loading = loading
    if (loading === true) state.loadingerror = false
  },
  setLoadingError (state, errormsg) {
    state.loadingerror = true
    state.loadingerrormessage = errormsg
  },
  setReports (state, { reports }) {
    state.page_current_offset = ''
    state.page_next_offset = ''

    if (reports.requests && reports.requests.length) {
      reports.requests.forEach(function (element) {
        element._showDetails = false
      })

      state.page_current_offset = reports.requests[0].id
    }

    if (reports.offsetNext) {
      state.page_next_offset = reports.offsetNext
    }

    state.rawdata = reports
    state.reports = reports.requests
  },
  setReportDetails (state, { reportdetails }) {
    const reportid = reportdetails.id
    state.reportdetails[reportid] = reportdetails
  },
  updateReportsFromDetails (state, { reportdetails }) {
    const searchid = reportdetails.id

    // update item in list
    state.reports.forEach(function (element) {
      if (element.id !== searchid) return

      element.customer = reportdetails.customer
      element.status = reportdetails.status
      element.type = reportdetails.type
      element.receivedDate = reportdetails.receivedDate
      element.id = reportdetails.id
    })

    state.reportdetails[searchid] = reportdetails
  },
  toggleDetails (state, row) {
    state.reports[row]._showDetails = !state.reports[row]._showDetails
  },
  setRAWEmail (state, rawemail) {
    state.rawemail = rawemail
  }
}

const getters = {
  getReportDetails: (state) => (id) => state.reportdetails[id],
  getRawEmail: (state) => state.rawemail
}

const actions = {
  getReports ({ commit }, { searchOpts, brandid }) {
    commit('resetStatusFlags')
    commit('changeReportLoadingState', true)

    api
      .getReports({ searchOpts, brandid })
      .then((reports) => {
        commit('setReports', { reports })
        commit('changeReportLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changeReportLoadingState', false)
      })
  },
  retrieveReportDetails ({ commit }, { reportid, brandid }) {
    commit('resetStatusFlags')
    commit('changeReportLoadingState', true)
    return api
      .getReportDetails({ reportid, brandid })
      .then((reportdetails) => {
        commit('setReportDetails', { reportdetails })
        commit('changeReportLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changeReportLoadingState', false)
      })
  },

  updateReportDetails ({ commit }, { reportid, brandid }) {
    commit('resetStatusFlags')
    commit('changeReportLoadingState', true)

    return api
      .getReportDetails({ reportid, brandid })
      .then((reportdetails) => {
        commit('updateReportsFromDetails', { reportdetails })
        commit('changeReportLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changeReportLoadingState', false)
      })
  },
  getMail ({ commit }, { reqid, brandid }) {
    commit('resetStatusFlags')
    commit('changeReportLoadingState', true)
    return api
      .getMail({ reqid, brandid })
      .then((result) => {
        commit('setRAWEmail', result)
        commit('changeReportLoadingState', false)
      })
      .catch((error) => {
        console.error(error)
        try {
          error = error + ' [ ' + error.response.data.message + ' ]'
        } catch (e) {}
        commit('setLoadingError', error)
        commit('changeReportLoadingState', false)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
